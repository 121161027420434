/* TODO Add site wide styles */
html,body {
    margin:0;
    padding:0;
    margin-top: 0px;
    font-family:  sans-serif;
    overflow: hidden;
    overflow-wrap: break-word;
    background-color: rgb(245, 237, 237);
}

.char-counter {
    color: grey;
    font-size: smaller;
}

.char-counter-max  {
    color: rgb(157, 81, 71);
    font-size: smaller;
}

#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}


#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  /* opacity: 1;
    transition: 1ms; */
}

#modal-content {
  position: absolute;
  background-color: rgb(238, 238, 238);
  min-height: 350px;
  width: 500px;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  /* opacity: 1; */
}

.channel-card-header-wrapper {
  background-color: rgb(102, 2, 2);
  height: 100px;
}

.cc-header {
  /* height:100%; */
  padding-left: 32px;
  padding-top: 30px;
  padding-right: 30px;
  background-color: rgb(102, 2, 2);
  font-size: large;
  color: white;
}

.cc-header-menu {
  padding-left: 30px;
  padding-top: 25px;
  display: flex;
  flex-direction: row;
}

.cc-header-menu-option-wrapper {
  padding-right: 30px;
}

.cc-header-menu-option {
  color: rgb(193, 191, 191);
}

.cc-header-menu-option-selected {
  transition: 50ms;
  text-underline-offset: 5px;
  color: white;
  font-weight: 500;
  padding-bottom: 4px;
  border-bottom: rgb(241, 236, 236) solid 3px;
  /* border-right-style: risdge; */

}

.channel-form {
  background-color: white;
  border-radius: 10px;
  border: rgb(211, 211, 211) solid 1px;
  /* top: -10px; */
  margin: 30px;
  /* display:flex;
    flex-direction: column; */
}

.cc-form-item {
  height: 60px;
  padding: 20px;
  padding-bottom: 10px;
  border-bottom: rgb(211, 211, 211) solid 1px;
  transition: 100ms;
}

.form-input-header {
  display: flex;
  justify-content: space-between;
}

.cc-form-item:hover {
  background-color: rgb(232, 230, 230);
}

.cc-form-button {
  height: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: rgb(211, 211, 211) solid 1px;
}

.cc-form-item-save {
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: rgb(211, 211, 211) solid 1px;
  transition: 100ms;
}

.cc-form-item>textarea {
  width: 99%;
  min-height: 30px;
  margin: 0px;
  box-sizing: border-box;
  padding: 5px;
  border: 2px solid rgb(95, 78, 78);
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
}

/* .cc-form-item > textarea:focus {
    background-color: red;
    font-size: xx-large;
  } */

.cc-update-buttons {
  padding-top: 5px;
  width: 260px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}

.cc-update-buttons>div {
  border: rgb(211, 211, 211) solid 1px;
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px;
  transition: 100ms;
}

.cc-update-save:hover {
  background-color: rgb(169, 210, 169);
  transition: 100ms;
}

.cc-update-cancel:hover {
  background-color: rgb(226, 177, 164);
  transition: 100ms;
}

.cc-delete-button {
  padding: 10px;
  margin: 10px;
}

.cc-update-delete:hover {
  background-color: rgb(226, 177, 164);
  transition: 100ms;
}

.prof-button-wrapper {
  padding-right: 40px;
}

.profile-button {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  overflow: hidden;
}




.profile-button>img {
  /* object-fit: cover; */
  width: 35px;
  height: 35px;
}


.profile-button>img:hover {
  /* object-fit: cover; */
  opacity: 0.8;
}

.profile-menu-button {
  text-align: left;
  border-radius: 5px;
  background-color: transparent;
  border: none;
  color: rgb(92, 60, 60);
  flex-wrap: nowrap;
  font-size: medium;
}

.profile-menu-button:hover {
  background-color: rgb(151, 107, 107);
  color: white;
  border: none;
}


.home-topbar {
  padding-left: 10px;
  width: 100%;
  height: 40px;
  background-color: rgb(60, 8, 8);
  color: lightgrey;
  font-size: medium;
  display: flex;
  align-items: center;
}

.profile-dropdown {
  position: absolute;
  background-color: rgb(255, 255, 255);
  height: 80px;
  right: 55px;
  margin-top: 5px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 1px 1px;
}

.profile-menu-item {}

.hidden {
  display: none;
}
